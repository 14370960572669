@charset 'UTF-8';
@import './setting/variables';

.GPC0058 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding-left:0;
		padding-right:0;
	}
	.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
		max-width:800px;
		margin:0 auto 40px;
		font-size:40px;
		line-height:48px;
		@include font-family($font-semibold);
		color:$color-black;
		text-align:center;
		@include screen(custom, max, $max-lg) {
			margin-bottom:15px;
		}
		@include screen(custom, max, $max-md) {
			font-size:36px;
			line-height:44px;
		}
		@include screen(custom, max, $max-sm) {
			margin:0 0 11px;
			font-size:28px;
			line-height:32px;
		}
		@include screen(custom, max, 374) {
			font-size:26px;
			line-height:30px;
		}
	}
	.title {
		@include screen(custom, max, $max-sm) {
			padding-left:24px;
			padding-right:24px;
		}
	}
	.tab-area {
		position:relative;
		margin-bottom:40px;
		.link {
			position:absolute;
			z-index:1;
			right:0;
			top:50%;
			transform:translateY(-50%);
			-o-transform:translateY(-50%);
			-ms-transform:translateY(-50%);
			-moz-transform:translateY(-50%);
			width:18%;
			padding:0 24px;
			text-align:right;
			[dir="rtl"] & {
				right:auto;
				left:0;
				@include screen(custom, min, $min-xl){
					text-align:left;
				}
			}
			.link-text {
				text-decoration:none;
				text-transform:uppercase;
				@include font-family($font-bold); // should be replace bold class
				&.font-underline {
					text-decoration:underline;
				}
			}
			@include screen(custom, max, $max-lg) {
				position:static;
				transform:none;
				width:100%;
				margin-bottom:28px;
				text-align:center;
			}
			@include screen(custom, max, $max-sm) {
				margin-bottom:15px;
			}
		}
		@include screen(custom, max, $max-sm) {
			margin-bottom:20px;
			.tab-wrap-outer {
				padding:0 24px;
			}
		}
		@include screen(custom, min, $min-md) {
			.tab-wrap {
				.tabs-type-rect {
					display:table;
					margin:0 auto;
					// WA-Common-Tab : tab 영역 ul-li 구조화 시작
					li {
						position: relative; 
						display:table-cell;
						width:257px;
						word-break:break-word;
						vertical-align:middle;
						white-space:normal;
						border:1px solid #d9d9d9;
						border-left:none;
						&:first-child {border-left:1px solid #d9d9d9;}
						[dir="rtl"] & {
							&:first-child {border-left:none;}
							&:last-child {border-left:1px solid #d9d9d9;}
						}
						a {
							border: none;
							line-height: 16px;
							padding: 0;
							width: inherit;
							height: 60px;
							display: table-cell;
							padding: 14px 20px;
							vertical-align: middle;
							&.active:after {
								content:'';
								display: block;
								position: absolute;
								left: 0;
								bottom: -1px; // LGEPA-401
								width: 100%;
								height: 0;
								border-top: 3px solid $color-carmine;
							}
						}
					}
					// WA-Common-Tab : tab 영역 ul-li 구조화 끝
				}
				// 20200525 START 이상현 - 768px 이상 화면에서 탭의 스크롤 버튼 노출 방지.
				.tab-scroll-controller {
					display:none; // mscrollbar의 768 이상 구간에서의 너비 계산 알고리즘의 문제를 방어.
				}
				// 20200525 END
			}
		}
	}
	.promo-area {
		display:none;
		&.active {display:block;}
		@extend %clearfix;
		@include screen(custom, max, $max-sm) {
			background:$bg-whitesmoke;
		}
		.img-area {
			overflow:hidden;
			float:left;
			position:relative;
			height:700px;
			width:(630px / 1284px) * 100%;
			margin-right:(24px / 1284px) * 100%;
			[dir="rtl"] & {
				@include screen(custom, min, $min-xl) {
					float:right;
					margin-right:0;
					margin-left:(24px / 1284px) * 100%;
				}
			}
			@include screen(custom, max, $max-lg) {
				float:none;
				height:264px;
				width:auto;
				margin:0;
			}
			@include screen(custom, max, $max-sm) {
				height:auto;
				width:auto;
			}
			&.text-white {
				background-color: $bg-gray;
				.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6 {color:$color-white;}
				.body-copy {color:$color-white;}
				.link {
					.link-text {
						color:$color-white;
						&.ico-right:after {
							background-image:url($link-right-white); //LGCOMSPEED-6(8th)
						}
					}
				}
			}
			&.text-black {
				background-color: $bg-white;
				.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6 {color:$color-black;}
				.body-copy {color:$color-black;}
				.link {
					.link-text {
						color:$color-black;
						&.ico-right:after {
							background-image:url($link-right-dark); //LGCOMSPEED-11
						}
					}
				}
			}
			.img {
				position:absolute;
				top:0;
				left:50%; margin-left:-315px;
				height:700px;
				@include screen(custom, max, $max-lg) {
					margin-left:-599.5px;
					height:264px;
				}
				@include screen(custom, max, $max-sm) {
					//margin-left:-398.5px; height:213px;
					position:static;
					margin-left:0; height:auto;
				}
				img {
					max-height:100%;
					@include screen(custom, max, $max-sm) {
						max-width:100%;
						max-height:none;
					}
				}
				img.lazyload,
				img.lazyloaded {
					&.mobile {display:none;}
					&.pc-md {display:none;}
					@include screen(custom, max, $max-lg) {
						&.pc-lg {display:none;}
						&.pc-md {display:inline-block;}
					}
					@include screen(custom, max, $max-sm) {
						&.pc-md {display:none;}
						&.mobile {display:inline-block;}
					}
				}
			}
			.text-block {
				position:absolute;
				left:0; bottom:(45px / 700px) * 100%;;
				width:100%;
				padding:0 ((40px / 630px) * 100%);
				@include screen(custom, max, $max-lg) {
					top:50%;
					bottom:auto;
					transform:translateY(-50%);
					padding:0 ((50px / 1199px) * 100%);
				}
				@include screen(custom, max, $max-sm) {
					top:50%;
					transform:translateY(-50%);
					padding:0 24px;
				}
			}
			.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6 {
				font-size:36px;
				line-height:40px;
				@include font-family($font-semibold);
				color:$color-white;
				@include screen(custom, max, $max-lg) {
					font-size:36px;
					line-height:40px;
				}
				@include screen(custom, max, $max-sm) {
					font-size:24px;
					line-height:28px;
				}
			}
			.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6, .body-copy, .link {
				width:350px;
				@include screen(custom, max, $max-lg) {
					width:340px;
				}
				@include screen(custom, max, $max-sm) {
					width:175px;
				}
			}
			.body-copy {
				margin:16px 0 0;
				font-size:16px;
				line-height:24px;
				color:$color-white;
				&.font-underline {
					text-decoration:underline;
				}
				@include screen(custom, max, $max-sm) {
					display:none;
				}
			}
			.link {
				margin:16px 0 0;
				@include screen(custom, max, $max-sm) {
					max-width:100%;
					margin-top:11px;
				}
				.link-text {
					color:$color-white;
					text-decoration:none;
					text-transform:uppercase;
					&.ico-right:after {
						background-image:url($link-right-white); //LGCOMSPEED-6(8th)
					}
					&.font-underline {
						text-decoration:underline;
					}
				}
			}
			
			// new (align)
			&.align-left {
				.text-block {
					.sub-title,
					.body-copy,
					.link {
						margin-right:auto;
					}
				}
				@include screen(custom, max, $max-lg) {
					@include screen(custom, min, $min-md) {
						.img {
							left:0;
							margin-left:0;
							margin-right:0;
						}
					}
				}
				[dir="rtl"] & {
					.text-block {
						text-align:right;
					}
				}
			}
			&.align-center {
				.text-block {
					text-align:center;
					.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6, .body-copy, .link {
						margin-left:auto;
						margin-right:auto;
						width:400px;
						@include screen(custom, max, $max-lg) {
							width:430px;
						}
					}
					@include screen(custom, max, $max-sm) {
						.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6 {
							width:250px;
						}
					}
				}
			}
			&.align-right {
				.text-block {
					text-align:left;
					.sub-title, .sub-title h1, .sub-title h2, .sub-title h3, .sub-title h4, .sub-title h5, .sub-title h6, .body-copy, .link {
						float:right;
						clear:both;
					}
				}
				[dir="rtl"] & {
					.text-block {
						text-align:right;
					}
				}
				@include screen(custom, max, $max-lg) {
					@include screen(custom, min, $min-md) {
						.img {
							left:auto;
							right:0;
							margin-left:0;
							margin-right:0;
						}
					}
				}
			}
		}
		.list-area {
			overflow:hidden;
			float:right;
			[dir="rtl"] & {
				float:left;
			}
			width:(630px / 1284px) * 100%;
			background:$bg-whitesmoke;
			padding:15px 15px;			
			@include screen(custom, max, $max-lg) {
				float:none;
				width:100%;
				padding: 20px 45px 25px;
				position: relative;
			}
			@include screen(custom, max, 320) {
				padding: 20px 15px 25px;
			}
			.item-group, .item-group-set {
				@extend %clearfix;
				margin-left:-15px;
				margin-top:-15px;
				opacity:0;
				@include screen(custom, max, $max-lg) {
					margin-left:0;
					margin-top:0;
					height:465px;
					opacity:0;
					overflow:hidden;						
					&.slick-initialized{
						opacity:1;
						overflow:initial;
						height:auto;
					}
				}
				&.load{
					opacity:1;
					transition:all 0.1s;
				}
			}
			.item {
				padding-left:15px;
				padding-top:15px;
				float:left;
				[dir="rtl"] & {
					float:right;
				}
				width:50%;
				@include screen(custom, max, $max-lg) {
					padding-left:0;
					padding-top:0;
					margin:0 7px;
				}
				&:focus{
					outline:0;
					.item-inner.on{
						outline-offset: -2px;
   						outline: inset;
					}
				}
				.item-inner{
					position:relative;
					padding:12px 15px 10px;
					background:$bg-white;
					text-align:center;
					border-radius:8px;
					height:327.5px;			
					@include screen(custom, min, $min-xl) {
						&:hover,&.on{
							box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
							.model-name,.price-vip-Installment,.price-area,.retailer{
								display:none;
							}
							// LGEFR-650
							.file-list {
								display: inline-flex;
								align-items: center;
							}
							.stock-area,.button,.repairability-index {
								display:block;
							}
							.hover-box{ //버튼 고정을 위한 높이
								padding-top:20px;
								height:125px;
							}
						}
					}
					@include screen(custom, max, $max-lg) {
						padding:20px 15px 35px;
						height:auto !important;
						// LGEFR-650
						.stock-area,.button,.repairability-index {
							display:block;
						}
						.file-list {
							display: inline-flex;
						}
						.hover-box{ 
							min-height:267px;
						}
					}
				}
				.img {
					a {
						// LGECZ-202 start
						overflow:hidden;
						display:inline-block;
						width:120px; 
						height:120px;
						vertical-align:top;
						img {width:100%;height:auto;line-height:120px;}
						@include screen(custom, max, $max-lg) {
							width: 150px; 
							height: 150px;
							img {
								line-height: 150px;
							}
						}
						// LGECZ-202 end
					}
				}
				.model-name {
					margin:5px auto;
					a {
						// LGECZ-202 start
						width:100%;
						height:43px; 
						overflow:hidden;
						text-overflow:ellipsis;
						display:block;
						display:-webkit-box;
						-webkit-line-clamp:2;
						-webkit-box-orient:vertical;
						margin:0 auto;
						font-size:15px;
						line-height:22px;
						color:$color-nightrider;
						@include font-family($font-semibold);
						@include screen(custom, max, $max-lg) {
							height: 50px; 
							font-size: 16px;
							line-height: 24px;
						}
						// LGECZ-202 end
					}
				}
				.rating {
					margin:0 auto;
					height:26px;
					.bv_stars_component_container {
						line-height:11px !important;
					}
				}
				.file-list {
					display:none;
					margin:2px auto 5px;
					> a {
						display: inline-flex;
						align-items: center;
						margin: 0 2px;
						text-decoration: none;
					}
					.energy-label-wrap {
						margin: 0 2px;
					}
					a {
						&:hover {
							text-decoration: none;
						}
						&.link-text{
							vertical-align: top;
						}
					}
					.fiche {
						display: inline-block;
						vertical-align: top;
						font-size:12px;
						color: #6b6b6b;
						font-style: normal;
						max-width: 150px;
						// overflow: hidden;
						// text-overflow: ellipsis;
						// white-space: nowrap;
						line-height: 1;
						&.type-none {
							display: none;
						}
						+ .label {
							margin-left: 10px;
						}
					}
					//LGEGMC-1296 : start
					// LGEES-494 : energy label 영역 변경
					.label {
						display: inline-block;
						vertical-align: top;
						// width: 42px;
						height: 36px;
						font-size: 0;
						@include screen(custom, max, $max-sm) {
							// width: 42px;
							height: 36px;
						}
						> img {
							width: 100%;
							height: 100%;
							// @include screen(custom, max, $max-sm) {
							// 	width: 100%;
							// }
						}
					}
					//LGEGMC-1296 : end
				}
				.price-vip-Installment{
					margin:0 auto 3px;
					min-height:48px;
					.price-vip{
						font-size:13px;
					}
					.price-installment{
						font-size:14px;
						line-height:18px;
						max-width:190px;
						text-decoration:none !important;
						margin:2px auto 0;
					}
					/* PJTOBS-32 End */
					// PJTLIMITQTY-4 : s
					&.limited-quantity-area {
						min-height: 52px;
						.price-vip-Installment {
							.price-limited {
								font-size: 14px;
								font-weight: 700;
								text-align: inherit;
								text-transform: uppercase;
							}
						}
					}
					// PJTLIMITQTY-4 : e
				}
				.price-area {
					margin:0 auto;					
					.purchase-price {
						display:inline-block;
						vertical-align: middle;
						font-size:22px;
						line-height:32px;
						color:$color-black;
						@include font-family($font-semibold);						
					}
					.product-price {
						display:inline-block;
						vertical-align: middle;
						font-size:12px;
						line-height:1.2em;
						@include font-family($font-semibold);
						margin-left:6px;
						text-align:left;
						[dir="rtl"] & {
							margin-left:0;
							margin-right:6px;
							text-align:right;
						}
						@include screen(custom, max, $max-lg) {
							text-align: center;
							display:block;
						}
						.price,
						.legal {
							// WA-Common-Price : price 마크업 div → del 수정
							display: block;
							font-size:13px;
							line-height:16px;
							@include font-family($font-semibold);
							@include screen(custom, min, $min-md) {
								min-height:16px;
								text-align:center;
							}
							@include screen(custom, max, $max-sm) {
								display:inline-block;
								[data-countrycode=it] &{
									display:block !important;
									text-align:center !important;
									br{
										display:none !important;
									}
								}
							}
							&.and-txt{
								@include screen(custom, max, $max-lg) {
									display:block;
									text-align:center;
									.legal-txt{display:inline-block;}
									.legal-price{display:inline-block;}
								}
							}
						}
						.price {
							color:$color-dimgray;
							text-decoration: none;
							span:not(.uvp) {
								text-decoration: line-through;
							}
							// [Start] LGEDE-1151
							&.has-uvp{
								font-size: 0;
								.uvp{
									margin-right: 3px;
									vertical-align: bottom;
									font-size: 14px;
									& ~ span{
										text-decoration: line-through;
										vertical-align: bottom;
										font-size: 14px;
									}
								}
							}
							// [End] LGEDE-1151
							// LGCOMPL-219 start
							.lowest-price-mark {
								margin-left: 2px;
								font-size: 14px;
								vertical-align: middle;
							}
							// LGCOMPL-219 end
						}
						.legal {color:$color-carmine;}
						[data-countrycode=it] &{
							@include screen(custom, max, $max-sm) {
								display: block;
								.price{
									display: block;
									text-align: center;
									line-height: 16px;
								}
								.legal{
									display: block;
									text-align: center;
									margin-top:0;
									line-height: 16px;
									br{
										display:none !important;
									}
								}
							}
						}
					}
					.member-text{
						display:none;
						@include screen(custom, max, $max-lg) {
							display:block;
						}
					}
				}
				.stock-area {
					display:none;
					min-height: 18px;
					margin-bottom: 10px;
					text-align: center;
					.icon {
						display: inline-block;
						vertical-align: middle;
					}
					.text {
						display: inline-block;
						@include font-family($font-regular);
						font-size: 16px;
						line-height: 20px;
						vertical-align: middle;
					}
					&.out-of-stock {
						.icon {
							width: 16px;
							height: 16px;
							background:url('/lg5-common-gp/images/common/icons/stock-out.svg') no-repeat 0 0;
							background-size: 16px;
							line-height: 16px;
						}
						.text {
							color: $color-carmine;
						}
					}
				}
				.retailer {
					height:36px;
					font-size:16px;
					line-height:24px;
					color:$color-black;
					@include font-family($font-semibold);
					margin-bottom:11px;
				}
				.button {
					display:none;
					.btn {
						width:100%;
						max-width:250px;
						&.re-stock-alert {
							padding: 9px 7px;
						}
						@include screen(custom, max, $max-sm) {
							
						}
					}
				}
				// LGEFR-650
				.repairability-index {
					display: none;
				}
			}
		}
		&.row-3 {
			@include screen(custom, min, $min-xl) {
				.list-area{
					padding:15px 15px;
					.item-group{
						margin-left:-15px;
					}
					.item {
						padding-left: 15px;
						&:nth-child(1){
							width:100%;
							float:none;
							.item-inner{
								padding:35px 45px;
								box-shadow: inherit !important;
								display: flex;
								align-items: center;
								.img{
									margin-left: 10px;
									a{
										width: 180px;
										height: 180px;
										img{
											line-height: 180px;
										}
									}
								}
								.model-name{
									margin-top:0;
									margin-bottom:10px;
									a{
										font-size:16px;
									}
								}
								.file-list{
									margin: 2px auto 10px;
								}
								.price-vip-Installment{
									min-height:auto !important;
								}
								.info-area{
									margin-left: 60px;
									width: 255px;
								}
								.hover-box{
									padding-top:0;
									height:auto !important;
									.model-name,.price-vip-Installment,.price-area,.retailer,.stock-area{
										display:block !important;
									}
									.file-list{
										display:inline-flex !important;
									}
								}
								.button{
									margin-top:13px;
									display:block !important;
								}
								// LGEFR-650 start
								.repairability-index {
									display: block !important;
									margin: -5px 0 10px;
								}
								// LGEFR-650 end
							}
						}
					}	
				}	
			}
		}
		&.row-2 {
			@include screen(custom, min, $min-xl) {
				.list-area{
					padding:15px 15px;
					.item-group{
						margin-left:0;
						margin-top:0;
					}
					.item {
						width:100%;
						float:none;
						padding-left: 0;
						padding-top: 0;
						margin-top:15px;
						&:first-child{
							margin-top:0 !important;
						}
						.item-inner{
							padding:35px 45px;
							box-shadow: inherit !important;
							display: flex;
							align-items: center;
							.img{
								margin-left: 10px;
								a{
									width: 180px;
									height: 180px;
									img{
										line-height: 180px;
									}
								}
							}
							.model-name{
								margin-top:0;
								margin-bottom:10px;
								a{
									font-size:16px;
								}
							}
							.file-list{
								margin: 2px auto 10px;
							}
							.price-vip-Installment{
								min-height:auto !important;
							}
							.info-area{
								margin-left: 60px;
								width: 255px;
							}
							.hover-box{
								padding-top:0;
								height:auto !important;
								.model-name,.price-vip-Installment,.price-area,.retailer,.file-list,.stock-area{
									display:block !important;
								}
								// LGEFR-650 start
								.repairability-index {
									display: block !important;
									margin: -5px 0 10px;
								}
								// LGEFR-650 end
							}
							.button{
								margin-top:13px;
								display:block !important;
							}
						}
					}	
				}	
			}
		}
		&.type-set{
			.list-area{
				padding:7.5px 7.5px;
				min-height:700px;
				overflow:hidden;
				.item-group-set{
					margin-left: 0px;
					margin-top: 0px;
					max-height:670px;
					overflow:hidden;
					.item{
						padding:7.5px 7.5px; 
						.item-inner{
							height:320px;
						}
					}
					&.slick-initialized{
						max-height:none;
						overflow:initial;
					}
				}
				@include screen(custom, max, $max-lg) {
					min-height:auto;
					max-height:auto;
					padding: 20px 45px 25px;
					.item-group-set{
						.item{
							margin:0 0;
							padding:0 7.5px 0;
						}
					}
				}
				@include screen(custom, max, 320) {
					padding: 20px 15px 25px;
				}
			}
		}
	}
	.slick-indicator {
		position:relative;
		z-index:2;
		left:0;
		right:0;
		text-align:center;
		line-height: 1;
		font-size:0;
		margin-top:1px;
		@include screen(custom, max, $max-lg) {
			margin-top:20px;
		}
		.slick-dot-wrap {
			display: inline-block;
			vertical-align:top;
		}
		.slide-pause {
			display:inline-block;
			overflow:hidden;
			white-space:nowrap;
			vertical-align:top;
			width:10px;
			height:12px;
			background:url($pause-dimgray) no-repeat; //LGCOMSPEED-11
			background-size:100% 100%;
			color:transparent;
			margin-top:-1px;
			margin-left:8px;
			[dir="rtl"] & {
				margin-left:0;
				margin-right:8px;
			}
			&.play {
				background-image:url('/lg5-common-gp/images/common/icons/play-dimgray.svg');
			}
		}	
	}
	.item-group + .slick-indicator{
		@include screen(custom, min, $min-xl) {
			display:none !important;
			.slide-pause{
				display:none !important;
			}
		}
	}
	.slick-dots {
		line-height:1;
		li {
			position:relative;
			display:inline-block;
			vertical-align:top;
			width:10px;
			height:10px;
			margin:0 4px 3px;
			border-radius:50%;
			border:1px solid $color-gray;
			line-height:normal;
			a, button {
				background: $color-white;
				display:block;
				width:100%;
				height:100%;
				margin:0;
				padding:0;
				border:0;
				border-radius:50%;
				overflow:hidden;
				color:transparent;
				cursor: pointer;
				&:focus {
					outline:1px dotted;
					outline:5px auto -webkit-focus-ring-color;
				}
			}
			&.slick-active {
				border:1px solid $color-carmine;
				a, button {
					background:$color-carmine;
				}
			}
			&:only-child {
				display:none;
			}
		}
	}
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
		.slick-track,
		.slick-list {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			-o-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
		&:focus {outline:none;}
		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}
	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
		&:before,
		&:after {
			display: table;
			content: '';
		}
		&:after {
			clear:both;
		}
	}
	.slick-loading .slick-track{visibility: hidden;}
	.slick-slide{
		display: none;
		height: 100%;
		min-height: 1px;
		float: left !important;
		[dir='rtl'] & {float:right;}
		img {display:block;}
		&.slick-loading {
			img {
				display:none;
			}
		}
		&.dragging img {pointer-events: none;}
	}
	.slick-initialized .slick-slide{display: block;}
	.slick-loading .slick-slide{visibility: hidden;}
	.slick-vertical .slick-slide{
		display: block;
		height: auto;
		border: 1px solid transparent;
	}

	.slick-arrow.slick-hidden {display: none;}
	.slick-arrow{
		position:absolute;
		top:50%;
		width:15px;
		height:28px;
		display:block;
		margin-top:-14px;
		text-indent:-9999px;
		border:0;
		cursor: pointer;
		z-index:5;
		&.slick-prev{
			background:url($carousel-left); //LGCOMSPEED-11
			background-size:15px 28px;
			left:0;
			@include screen(custom, max, $max-lg) {
				left:-25px;
			}
			@include screen(custom, max, 320) {
				left:-10px;
			}
		}
		&.slick-next{
			background:url($carousel-right); //LGCOMSPEED-11
			background-size:15px 28px;
			right:0;
			@include screen(custom, max, $max-lg) {
				right:-25px;
			}
			@include screen(custom, max, 320) {
				right:-10px;
			}
		}
		@include screen(custom, min, $min-xl) {
			display:none;
		}
	}
	.slick-initialized{
		@include screen(custom, min, $min-xl) {
			&:hover{
				.slick-arrow{
					display:block;
				}
			}
		}
	} 
	// LGEPA-401 : start
	&.tabfull-link {
		.tab-area {
			.link {
				@include screen(custom, min, $min-lg) {
					position: static;
					margin-top: -12px;
					margin-bottom: 15px;
					width: auto;
					text-align: center;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					margin-top: 0;
					margin-bottom: 28px;
				}
			}
		}
	}
	// LGEPA-401 : end
	// LGECZ-202 start
	.slick-indicator {
		.slick-count {
			display: none;
		}
		@include screen(custom, max, $max-lg) {
			margin-top: 15px;
		}
		.slick-dot-wrap {
			@include screen(custom, max, $max-sm) {
				display: none;
			}
		}
		.slide-pause {
			width: 12px;
			@include screen(custom, max, $max-sm) {
				width: 32px;
				height: 32px;
				background-size: 12px;
				background-position: 50%;
			}
		}
		.slick-count {
			@include screen(custom, max, $max-sm) {
				display: inline-block;
				margin-left: 40px;
				line-height: 1;
				vertical-align: middle;
				[dir='rtl'] & {
					margin-left: 0;
					margin-right: 40px;
				}
			}
			.count-text {
				display: inline-block;
				width: 70px;
				font-size: 16px;
				line-height: 32px;
				color: $color-dimgray;
				text-align: center;
				vertical-align: middle;
			}
			button {
				position: relative;
				width: 32px;
				height: 32px;
				border: 1px solid $line-gray;
				vertical-align: middle;
				&.count-prev {
					background: $bg-white url($arrow-left-black) no-repeat 50%; //LGCOMSPEED-11
					background-size: 16px;
					[dir='rtl'] & {
						background-image: url('/lg5-common-gp/images/common/icons/arrow-right-black.svg');
					}
				}
				&.count-next {
					background: $bg-white url('/lg5-common-gp/images/common/icons/arrow-right-black.svg') no-repeat 50%;
					background-size: 16px;
					[dir='rtl'] & {
						background-image: url($arrow-left-black); //LGCOMSPEED-11
					}
				}
				&:disabled {
					opacity: 0.5;
				}
			}
		}
	}
	// LGECZ-202 end
}
